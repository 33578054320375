<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> Cadastro
      </div>
      <div class="text-500 mb-5">Preencha os campos abaixo</div>
      <div class="card p-fluid w-full">
        <h5 class="mb-4">Cadastro de Funcionário</h5>
        <Toast />
        <div class="flex-row">
          <div class="flex">
            <div class="field w-6 mr-2">
              <label for="nomeColaborador">Nome do Funcionário</label>
              <InputText
                id="nomeColaborador"
                v-model="form.name"
                type="text"
                placeholder="Digite aqui"
                :class="{ 'p-invalid': submitted && !form.name }"
              />
              <small class="p-error" v-if="submitted && !form.name"
                >Campo obrigatório.</small
              >
            </div>
            <div class="field w-6 mx-2">
              <label for="cpf">CPF</label>
              <InputMask
                id="cpf"
                :disabled="nasceuForaDoPais"
                v-model="form.cpf"
                mask="999.999.999-99"
                placeholder="Digite aqui"
                :class="{'p-invalid': submitted && !form.cpf && !nasceuForaDoPais}"
              />
          <small class="p-error" v-if="submitted && !form.cpf && !nasceuForaDoPais">Campo obrigatório.</small>
            </div>
            <div class="field w-6 ml-2">
              <label for="admissionDate">Data Admissão</label>
              <InputText
                id="admissionDate"
                type="date"
                v-model="form.admissionDate"
                placeholder="Digite aqui"
                :class="{ 'p-invalid': submitted && !form.admissionDate }"
              />
              <small class="p-error" v-if="submitted && !form.admissionDate"
                >Campo obrigatório.</small
              >
            </div>
          </div>
          <div class="field-checkbox">
            <Checkbox
              id="binary"
              v-model="nasceuForaDoPais"
              :binary="true"
              @click="limparCpf"
            />
            <label for="binary">Nasceu fora do país</label>
          </div>
          <div class="flex">
            <div class="field w-6 mr-2">
              <label for="sex">Sexo</label>
              <Dropdown
                id="sex"
                v-model="form.sex"
                optionValue="label"
                :options="sexo"
                optionLabel="label"
                placeholder="Selecione..."
                :class="{ 'p-invalid': submitted && !form.sex }"
              ></Dropdown>
              <small class="p-error" v-if="submitted && !form.sex"
                >Campo obrigatório.</small
              >
            </div>
            <div class="field w-6 mx-2">
              <label for="phone">Telefone Celular</label>
              <InputMask
                id="phone"
                mask="(99) 99999-9999"
                v-model="form.phone"
                placeholder="Digite aqui"
                :class="{ 'p-invalid': submitted && !form.phone }"
              />
              <small class="p-error" v-if="submitted && !form.phone"
                >Campo obrigatório.</small
              >
            </div>
            <div class="field w-6 ml-2">
              <label for="email">E-mail</label>
              <InputText
                id="email"
                v-model="form.email"
                type="text"
                placeholder="Digite aqui"
                :class="{ 'p-invalid': submitted && !form.email }"
              />
              <small class="p-error" v-if="submitted && !form.email"
                >Campo obrigatório.</small
              >
            </div>
          </div>
          <div class="flex">
            <div class="field w-6 mr-2">
              <label for="customerBranchesId">Unidade</label>
              <Dropdown
                id="customerBranchesId"
                v-model="form.customerBranchesId"
                :options="paginationUnidade"
                :filter="true"
                @filter="loadUnidades"
                autocomplete="true"
                optionLabel="name"
                optionValue="id"
                placeholder="Selecione..."
                :class="{ 'p-invalid': submitted && !form.customerBranchesId }"
              />
              <small
                class="p-error"
                v-if="submitted && !form.customerBranchesId"
                >Campo obrigatório.</small
              >
            </div>
            <div class="mx-2 w-6 field">
              <label for="customerDepartmentId">Setor</label>
              <Dropdown
                id="customerDepartmentId"
                v-model="form.customerDepartmentId"
                :options="paginationSetor"
                :filter="true"
                @filter="loadSetores"
                optionLabel="name"
                optionValue="id"
                placeholder="Selecione..."
                :class="{
                  'p-invalid': submitted && !form.customerDepartmentId,
                }"
              />
              <small
                class="p-error"
                v-if="submitted && !form.customerDepartmentId"
                >Campo obrigatório.</small
              >
            </div>
            <div class="ml-2 w-6 field">
              <label for="customerPositionId">Cargo</label>
              <Dropdown
                id="customerPositionId"
                v-model="form.customerPositionId"
                :options="paginationCargo"
                :filter="true"
                @filter="loadCargos"
                optionLabel="name"
                optionValue="id"
                placeholder="Selecione..."
                :class="{ 'p-invalid': submitted && !form.customerPositionId }"
              />
              <small
                class="p-error"
                v-if="submitted && !form.customerPositionId"
                >Campo obrigatório.</small
              >
            </div>
          </div>
        </div>
        <div class="field col align-content-end flex-wrap">
          <Button
            label="Salvar"
            icon="pi pi-check"
            class="p-button-primary button w-full"
            @click="save"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseService from "../../services/BaseService";

export default {
  data() {
    return {
      paginationCargo: [],
      paginationSetor: [],
      paginationUnidade: [],
      form: {
        // TODO custumerId esta chumbado, valor deve ser salvo automaticamente dependendo de qual custumerId esteja sendo acessado
        customerId: 1,
      },
      sexo: [{ label: "Masculino" }, { label: "Feminino" }, { label: "Outro" }],
      nasceuForaDoPais: false,
      submitted: false,
    };
  },

  async mounted() {
    this.$service = new BaseService("/customer/employees");
    this.$serviceCargo = new BaseService("/customer/positions");
    this.$serviceSetor = new BaseService("/customer/departments");
    this.$serviceUnidade = new BaseService("/customer/branches");
  },
  methods: {
    limparCpf() {
      this.form.cpf = null;
    },

    async save() {
      this.submitted = true;
      try {
        await this.$service.save(this.form);
        this.$toast.add({
          severity: "success",
          summary: "Funcionário cadastrado com sucesso!",
          life: 3000,
        });
        setTimeout(function () {
          window.close();
        }, 500);
        this.form = {};
        this.submitted = false;
      } catch (err) {
        this.$toast.add({
          severity: "error",
          summary: "Problemas ao cadastrar o Funcionário!",
          life: 3000,
        });
      }
    },

    async loadCargos(event) {
      if (event.value) {
        let { data } = await this.$serviceCargo.findAll({
          filter: event.value,
        });
        this.paginationCargo = data;
      }
    },
    async loadSetores(event) {
      if (event.value) {
        let { data } = await this.$serviceSetor.findAll({
          filter: event.value,
        });
        this.paginationSetor = data;
      }
    },
    async loadUnidades(event) {
      if (event.value) {
        let { data } = await this.$serviceUnidade.findAll({
          filter: event.value,
        });
        this.paginationUnidade = data;
      }
    },
  },
};
</script>

<style lang="scss">
.grid {
  margin: 15px;
}
</style>
